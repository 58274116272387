<template>
  <div id="cli_content" style="min-height: 100%">
    <div id="content" style="padding-bottom: 64px">
      <div id="title"><p>ST20115（S20G06-TJ）</p></div>
      <div class="box">
        <div class="type_show_url">
          <div class="border-b"></div>
          <div class="type_show_list">
            <a jump_click="" href="https://www.720yun.com/vr/0e824xpujci"
              ><span>👉 720°VR效果预览</span>
              <div class="weui_cell_ft"></div
            ></a>
          </div>
          <div class="border-b"></div>
          <div class="type_show_list">
            <a jump_click="" href="http://qr71.cn/o8jGHP/qdQBjJW"
              ><span>👉 产品详情介绍</span>
              <div class="weui_cell_ft"></div
            ></a>
          </div>
          <div class="border-b"></div>
          <div class="type_show_list">
            <a jump_click="" href="http://qr27.cn/FvSbmc"
              ><span>👉 木种详解</span>
              <div class="weui_cell_ft"></div
            ></a>
          </div>
          <div class="border-b"></div>
        </div>
        <div class="wechatdevtools-box" style="display: none">
          <div class="wechatmask"></div>
          <div class="weui-dialog">
            <div class="weui-dialog__hd">
              <strong class="weui-dialog__title">这是一个外部链接</strong>
            </div>
            <div class="weui-dialog__bd">
              小程序暂不支持打开外部链接，可点击复制链接后在浏览器中粘贴查看
            </div>
            <div class="weui-dialog__ft">
              <a
                href="javascript:;"
                class="weui-dialog__btn weui-dialog__btn_default"
                >取消</a
              ><a
                href="javascript:;"
                class="weui-dialog__btn weui-dialog__btn_primary"
                data-url=""
                >复制链接</a
              >
            </div>
          </div>
        </div>
        <div id="toast" style="display: none">
          <div class="weui-mask_transparent"></div>
          <div class="weui-toast">
            <p class="weui-toast__content">内容已复制</p>
          </div>
        </div>
        <textarea id="textarea_input" focus="false"></textarea>

        <div class="type_content_des">
          <div class="active_show_des">
            <p>
              <img
                src="//alicliimg.clewm.net/700/805/4805700/1622877423220385400e354670f9485798cf22d8c47c61622877421.jpg"
                style="max-width: 100%"
                title="imgNum_4472371"
              /><span></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#body {
  height: 100%;
}

html,
body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
#title {
  padding: 6px 16px 0;
  font-size: 22px;
  line-height: 30px;
  text-align: left;
  color: #000000;
  letter-spacing: 0px;
}
.border-b {
  height: 1px;
  background-color: #d9d9d9;
  margin-left: 16px;
}
.type_show_list {
  padding: 11px 16px;
  text-align: left;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 10px center;
}
a {
  text-decoration: none;
  display: block;
}
.type_show_list a {
  display: block;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  letter-spacing: 0px;
  padding-right: 16px;
}
.type_content_des {
  padding-top: 20px;
}
.wechatmask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
}
.weui-dialog {
  position: fixed;
  z-index: 5000;
  width: 80%;
  max-width: 300px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  text-align: center;
  border-radius: 3px;
  overflow: hidden;
}

.weui-dialog__hd {
  padding: 1.3em 1.6em 0.5em;
}
.weui-dialog__title {
  font-weight: 400;
  font-size: 18px;
}

.weui-dialog__bd {
  padding: 0 1.6em 0.8em;
  min-height: 40px;
  font-size: 15px;
  line-height: 1.3;
  word-wrap: break-word;
  word-break: break-all;
  color: #808080;
}
.weui-dialog__ft {
  position: relative;
  line-height: 48px;
  font-size: 18px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.weui-dialog__btn {
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  color: #09bb07;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
}

.weui-dialog__btn_default {
  color: #353535;
}

.weui-dialog__btn_primary {
  color: #586c94;
}

.weui-dialog__ft:after {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 1px;
  border-top: 1px solid #d5d5d6;
  color: #d5d5d6;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
}

.weui-dialog__btn:after {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  bottom: 0;
  border-left: 1px solid #d5d5d6;
  color: #d5d5d6;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scaleX(0.5);
  transform: scaleX(0.5);
}

#textarea_input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -10;
  text-indent: 9999;
}

.weui-mask_transparent {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.weui-toast {
  position: fixed;
  z-index: 5000;
  width: 7.6em;
  top: 180px;
  left: 50%;
  margin-left: -3.8em;
  background: rgba(17, 17, 17, 0.6);
  text-align: center;
  border-radius: 5px;
  color: #ffffff;
  font-size: 12px;
  height: 32px;
  line-height: 23px;
}

.weui-toast__content {
  margin: 5px 0 15px;
}
</style>
